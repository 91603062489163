import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { IoMenu } from "react-icons/io5";
import { VscChromeClose } from "react-icons/vsc";
import { db } from "../firebase";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { PiHeartDuotone } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import 'flag-icon-css/css/flag-icons.css'; // or 'flag-icon.min.css' depending on your setup
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import beyttyicon from "../assets/svg/beyttyicon.svg";





export default function Header() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode); // Assuming i18n is your translation library
  };
  const [openMenu, setOpenMenu] = useState(null);
  const handleToggle = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };
  const [pageState, setPageState] = useState("Sign in");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isCreateListingPopUpOpen, setCreateListingPopUpOpen] = useState(false);
  const location = useLocation();
  const [isAgentUser, setIsAgentUser] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const navigate = useNavigate();
  const navigateToResults = (filterType, filterListingType) => {
    // Use the `navigate` function to go to the Results page
    // Pass both filterType and filterListingType as query parameters
    navigate("/results", { state: { filterType, filterListingType } });
  };

  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  const [dropdownOpen, setDropdownOpen] = useState({
    buy: false,
    rent: false,
    sell: false,
    agents: false,
    help: false,
    listings: false,
    profile: false,
  });
  const [isMouseInDropdown, setIsMouseInDropdown] = useState(false);
  let timeoutId = null;

  const handleMouseEnter = (menu) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [menu]: true,
    }));
  };

  const handleMouseLeave = (menu) => {
    if (!isMouseInDropdown) {
      timeoutId = setTimeout(() => {
        setDropdownOpen((prev) => ({
          ...prev,
          [menu]: false,
        }));
      }, 300); // Adjust the delay time (in milliseconds) as needed
    }
  };

  const handleDropdownMouseEnter = () => {
    setIsMouseInDropdown(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  const handleDropdownMouseLeave = (menu) => {
    setIsMouseInDropdown(false);
    timeoutId = setTimeout(() => {
      setDropdownOpen((prev) => ({
        ...prev,
        [menu]: false,
      }));
    }, 300); // Adjust the delay time (in milliseconds) as needed
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
      } else {
        // User is not authenticated, reset states
        setPageState("Sign in");
        setIsAgentUser(false);
        setUserStatus(null);
      }

      // Set loading to false after the authentication state settles
      setLoading(false);
    });

    // Unsubscribe from the auth state listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos > scrollPosition && scrollDirection !== "down") {
        setScrollDirection("down");
      } else if (
        currentScrollPos < scrollPosition &&
        scrollDirection !== "up"
      ) {
        setScrollDirection("up");
      }

      setScrollPosition(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPosition, scrollDirection]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Check if the user is authenticated
        const user = auth.currentUser;
        if (!user) {
          // Handle the case where the user is not authenticated
          setIsAgentUser(false);
          setUserStatus(null);
          setPageState("Sign in");
          return;
        }

        // Set the page state to 'My Profile' since the user is authenticated
        setPageState("Profile");

        // Check if the user is an agent
        const agentStatus = await isAgent();
        setIsAgentUser(agentStatus);

        // Fetch user status
        const userDocRef = doc(db, "agents", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          // Assuming the user status is stored as a field named "status"
          const status = userDoc.data().status;
          setUserStatus(status);
        } else {
          // Handle the case where the user document doesn't exist
          setUserStatus(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [auth.currentUser]);

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  const toggleCreateListingPopUp = () => {
    setCreateListingPopUpOpen(!isCreateListingPopUpOpen);
  };
  const closeCreateListingPopUp = () => {
    setCreateListingPopUpOpen(false);
  };
  const openCreateListingPopUp = () => {
    setCreateListingPopUpOpen(true);
  };

  const isAgent = async () => {
    const agentDocRef = doc(db, "agents", auth.currentUser.uid);
    const agentDoc = await getDoc(agentDocRef);
    return agentDoc.exists();
  };

  if (location.pathname === "/results") {
    return null; // Don't render anything if the path is /results
  }

  
  return (
    <div
      className={`bg-white border md:sticky ${
        scrollDirection === "up" ? "top-0" : "-top-20"
      } z-40 transition-all duration-300`}
    >
      <header className="flex justify-between mt-4 mb-4 items-center px-4 md:px-8 mx-auto max-w-6xl">
        <div className="md:hidden flex container mx-auto w-full justify-center">
          <div className="flex justify-start w-full">
            <IoMenu className="w-8 h-8" onClick={toggleMobileMenu} />
          </div>
          <a className="absolute">
            <img
              src={process.env.PUBLIC_URL + "/Logo.png"}
              alt="Beytty Logo"
              className=" h-8"
              onClick={() => navigate("/")}
            />
          </a>
        </div>

        <div className="hidden relative mb-2 justify-center md:flex w-1/3 ">
          <ul className="md:flex w-full gap-4 md:gap-4 lg:gap-8 list-none">
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.buy ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("buy")}
              onMouseLeave={() => handleMouseLeave("buy")}
              onClick={() => {
                handleDropdownMouseLeave("buy");
                navigateToResults("sale");
              }}
            >
              {t("buy")}
            </li>
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.rent ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("rent")}
              onMouseLeave={() => handleMouseLeave("rent")}
              onClick={() => {
                handleDropdownMouseLeave("rent");
                navigateToResults("rent");
              }}
            >
              {t("rent")}
            </li>
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.sell ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("sell")}
              onMouseLeave={() => handleMouseLeave("sell")}
              onClick={() => navigate("/sell")}
            >
              {t("sell")}
            </li>
            <li
              className={` cursor-pointer pt-3 hover:text-red-800 ${
                dropdownOpen.agents ? "text-red-800" : ""
              }`}
              onMouseEnter={() => handleMouseEnter("agents")}
              onMouseLeave={() => handleMouseLeave("agents")}
              onClick={() => navigate("/agentlist")}
            >
              Agents
            </li>
          </ul>
        </div>
        <div className="hidden items-center justify-center mx-auto md:flex w-1/3">
          <img
            src={process.env.PUBLIC_URL + "/Logo.png"}
            alt="Beytty Logo"
            className="md:absolute-none opacity-100 h-7 sm:h-8 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="hidden md:flex gap-4 md:gap-4 w-1/3 lg:gap-8 items-center justify-end">
          {i18n.language !== "en" && (
            <button
              type="button"
              className="cursor-pointer text-xs hover:text-red-800"
              onClick={() => changeLanguage("en")}
              style={{ marginRight: "5px" }} // Add spacing between flag and text
            >
              <span className="flag-icon flag-icon-fr"></span>
            </button>
          )}

          {i18n.language !== "fr" && (
            <button
              type="button"
              className="cursor-pointer text-xs hover:text-red-800"
              onClick={() => changeLanguage("fr")}
              style={{ marginRight: "5px" }} // Add spacing between flag and text
            >
              <span className="flag-icon flag-icon-gb"></span>
            </button>
          )}
          {pageState === "Profile" && (
            <button
              type="submit"
              className="cursor-pointer text-2xl hover:text-red-800 "
              onClick={() => navigateAndCloseMobileMenu("/savedlistings")}
            >
              <PiHeartDuotone />
            </button>
          )}
          <button
            type="submit"
            className="cursor-pointer hover:text-red-800 "
            onClick={() => navigate("/help")}
          >
            {t("help")}
          </button>
          {pageState === "Sign in" && (
            <>
              <p
                className={`cursor-pointer  hover:text-red-800  ${
                  pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                    ? " text-black"
                    : " text-black"
                }`}
                onClick={() => navigate("/sign-in")}
              >
                {t(pageState)}{" "}
              </p>
              <p
                className="cursor-pointer  hover:text-red-800  "
                onClick={() => navigate("/sign-up")}
              >
                {t("join")}
              </p>
            </>
          )}

          {pageState === "Profile" && (
            <button
              type="submit"
              className="cursor-pointer hover:text-red-800 "
              onClick={() => navigate("/mylistings")}
            >
              {t("listings")}
            </button>
          )}

          {pageState === "Profile" && (
            <p
              className={`cursor-pointer  hover:text-red-800  ${
                pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                  ? " text-black"
                  : " text-black"
              }`}
              onClick={() => navigate("/profile")}
            >
              {t(pageState)}{" "}
            </p>
          )}
        </div>
      </header>

      {dropdownOpen.buy && (
        <div
          className="absolute mx-auto  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white   z-10"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("buy")}
        >
          <div className="flex flex-col   w-full max-w-6xl px-4">
            <h1
              className="cursor-pointer font-semibold  px-4 py-2 hover:text-red-800"
              onClick={() => {
                handleDropdownMouseLeave("buy");
                navigateToResults("sale");
              }}
            >
              {t("listingsforsale")}
            </h1>
            <div className="flex gap-10">
              <ul className="py-1  ">
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "villa");
                  }}
                >
                  {t("buyavilla")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "apartment");
                  }}
                >
                  {t("buyanapartment")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "commercial");
                  }}
                >
                  {t("buyacommerciallocal")}
                </li>
              </ul>
              <ul className="w-[1px] h-full bg-gray-200"></ul>
              <ul className="py-1 max-w-6xl ">
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "office");
                  }}
                >
                  {t("buyanoffice")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "riad");
                  }}
                >
                  {t("buyariad")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {dropdownOpen.rent && (
        <div
          className="absolute mx-auto  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white   z-10"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("rent")}
        >
          <div className="flex flex-col   w-full max-w-6xl px-4">
            <h1
              className="cursor-pointer font-semibold  px-4 py-2 hover:text-red-800"
              onClick={() => {
                handleDropdownMouseLeave("rent");
                navigateToResults("rent");
              }}
            >
              {t("listingsforrent")}
            </h1>
            <div className="flex gap-10">
              <ul className="py-1  ">
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "villa");
                  }}
                >
                  {t("rentavilla")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "apartment");
                  }}
                >
                  {t("rentanapartment")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "commercial");
                  }}
                >
                  {t("rentacommerciallocal")}
                </li>
              </ul>
              <ul className="w-[1px] h-full bg-gray-200"></ul>
              <ul className="py-1 max-w-6xl ">
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "office");
                  }}
                >
                  {t("rentanoffice")}
                </li>
                <li
                  className="cursor-pointer px-4 py-2 hover:text-red-800"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "riad");
                  }}
                >
                  {t("rentariad")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {dropdownOpen.sell && (
        <div
          className="absolute mx-auto  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white   z-10"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("sell")}
        >
          <div className="flex  gap-10 w-full max-w-6xl px-4">
            <ul className="py-1  ">
              <li className=" font-semibold  px-4 py-2 0">
                {" "}
                {t("sellingguide")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/sell");
                }}
              >
                {t("checkyouroptions")}
              </li>
              <li
                className="flex items-center  cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/beyttylisting");
                }}
              >
                {t("sellquicklywithbeytty")}
                <img
                  src={beyttyicon}
                  alt="Beytty Icon"
                  className="w-4 h-4 ml-1 mr-0.5"
                />
                <p className="font-bold mt-0.5">Beytty</p>
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/agentlist");
                }}
              >
                {t("sellwithaprofessionalagent")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("sell");
                  navigate("/create-listing");
                }}
              >
                {t("sellityourself")}
              </li>
            </ul>
            <ul className="w-[1px] h-full bg-gray-200"></ul>
            <ul className="py-1 max-w-6xl ">
              <li className="font-semibold  px-4 py-2 "> {t("more")}</li>
              <li className="cursor-pointer px-4 py-2 hover:text-red-800">
                {t("estimateyourhomevalue")}
              </li>
            </ul>
          </div>
        </div>
      )}
      {dropdownOpen.agents && (
        <div
          className="absolute mx-auto  flex justify-center border-t-[1px] border-b-[1px] py-4 w-full bg-white   z-10"
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={() => handleDropdownMouseLeave("agents")}
        >
          <div className="flex gap-10 w-full max-w-6xl px-4">
            <ul className="py-1  ">
              <li className="font-semibold  px-4 py-2 ">
                {t("lookingforanagent")}
              </li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agentlist");
                }}
              >
                {t("listofagents")}
              </li>
              <li
                className="cursor-pointer flex items-center px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agent/92kMF4IS32XDLbEA4Fqg3mFUW3D3");
                }}
              >
                {t("listingsof")}
                <img
                  src={beyttyicon}
                  alt="Beytty Icon"
                  className="w-4 h-4 ml-1 mr-0.5"
                />
                <p className="font-bold mt-0.5">Beytty</p>
              </li>
            </ul>
            <ul className="w-[1px] h-full bg-gray-200"></ul>
            <ul className="py-1 max-w-6xl ">
              <li className="font-semibold  px-4 py-2 "> {t("pro")}</li>
              <li
                className="cursor-pointer px-4 py-2 hover:text-red-800"
                onClick={() => {
                  handleDropdownMouseLeave("agents");
                  navigate("/agent-sign-up");
                }}
              >
                {t("createfreeagentaccount")}
              </li>
              <li className="cursor-pointer px-4 py-2 hover:text-red-800">
                {t("agentaedvertising")}
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Dark Overlay */}
      {isMobileMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-50"
          onClick={closeMobileMenu}
        />
      )}

      {/* Mobile Menu */}
      <div
        className={`md:hidden fixed left-0 -top-0 h-full w-64 z-50 transition-transform ease-in-out duration-300 transform ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-[-17rem]"
        }`}
      >
        {/* Mobile Menu Content */}
        <div className="bg-white overflow-y-auto shadow-lg h-full flex flex-col justify-between">
          {/* Logo and Close Icon */}
          <div className="flex justify-between items-center p-4">
            {/* Your Logo */}
            <img
              src={process.env.PUBLIC_URL + "/Logo.png"} // Replace with your logo path
              alt="Beytty Logo"
              className="w-24"
            />

            {/* Close Icon */}
            <VscChromeClose
              className="h-8 w-8 cursor-pointer"
              onClick={closeMobileMenu}
            />
          </div>

          {/* Menu Items */}
          <ul className="flex flex-col space-y-4 p-4">
            <li className="flex w-full items-center">
              <p
                onClick={() => {
                  navigateFilterAndCloseMobileMenu("sale");
                }}
              >
                {t("buy")}
              </p>
              <p
                onClick={() => {
                  handleToggle("sale");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "sale" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>
            </li>
            {openMenu === "sale" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "villa");
                  }}
                >
                  {t("buyavilla")}
                </p>{" "}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "apartment");
                  }}
                >
                  {t("buyanapartment")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "commercial");
                  }}
                >
                  {t("buyacommerciallocal")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "office");
                  }}
                >
                  {t("buyanoffice")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("buy");
                    navigateToResults("sale", "riad");
                  }}
                >
                  {t("buyariad")}
                </p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
            <li className="flex w-full items-center">
              <p
                onClick={() => {
                  navigateFilterAndCloseMobileMenu("rent");
                }}
              >
                {t("rent")}
              </p>
              <p
                onClick={() => {
                  handleToggle("rent");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "rent" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>
            </li>
            {openMenu === "rent" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "villa");
                  }}
                >
                  {t("rentavilla")}
                </p>{" "}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "apartment");
                  }}
                >
                  {t("rentanapartment")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "commercial");
                  }}
                >
                  {t("rentacommerciallocal")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "office");
                  }}
                >
                  {t("rentanoffice")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("rent");
                    navigateToResults("rent", "riad");
                  }}
                >
                  {t("rentariad")}
                </p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
            <li className="flex w-full items-center">
              {" "}
              <p onClick={() => navigateAndCloseMobileMenu("/Sell")}>
                {t("sell")}
              </p>
              <p
                onClick={() => {
                  handleToggle("sell");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "sell" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>{" "}
            </li>
            {openMenu === "sell" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}

                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/sell");
                  }}
                >
                  {t("checkyouroptions")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/beyttylisting");
                  }}
                >
                  {t("sellquickly")}
                  <p className="flex">
                    <p>{t("with")}</p>
                    <img
                      src={beyttyicon}
                      alt="Beytty Icon"
                      className="w-4 h-4 ml-1 mr-0.5"
                    />
                    <p className="font-bold">{t("Beytty")}</p>
                  </p>
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/agentlist");
                  }}
                >
                  {t("sellwithaprofessionalagent")}
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("sell");
                    navigateAndCloseMobileMenu("/create-listing");
                  }}
                >
                  {t("sellityourself")}
                </p>
                <p className="text-sm">{t("estimateyourhomevalue")}</p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
            <li className="flex w-full items-center">
              <p onClick={() => navigateAndCloseMobileMenu("/agentlist")}>
                {t("agents")}
              </p>
              <p
                onClick={() => {
                  handleToggle("agents");
                }}
                className="flex w-full pr-2 text-sm justify-end"
              >
                {openMenu === "agents" ? <FaChevronUp /> : <FaChevronDown />}{" "}
              </p>{" "}
            </li>
            {openMenu === "agents" && (
              <div className="space-y-3 opacity-80">
                {/* Add your subtitle or submenu items here */}
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu("/agentlist");
                  }}
                >
                  {t("listofagents")}
                </p>
                <p
                  className="text-sm flex items-center"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu(
                      "/agent/92kMF4IS32XDLbEA4Fqg3mFUW3D3"
                    );
                  }}
                >
                  {t("listingsof")}
                  <img
                    src={beyttyicon}
                    alt="Beytty Icon"
                    className="w-4 h-4 ml-1 mr-0.5"
                  />
                  <p className="font-bold mt-0.5">Beytty</p>
                </p>
                <p
                  className="text-sm"
                  onClick={() => {
                    handleDropdownMouseLeave("agents");
                    navigateAndCloseMobileMenu("/agent-sign-up");
                  }}
                >
                  {t("createfreeagentaccount")}
                </p>
                <p className="text-sm">{t("agentaedvertising")}</p>
              </div>
            )}
            <div className="border-t flex-1 after:border-gray-300"></div>
          </ul>
          {/* Last Menu Item */}
          <ul className="flex flex-col items-center mt-auto space-y-4 p-4">
            {i18n.language !== "en" && (
              <button
                type="button"
                className="cursor-pointer text-xs hover:text-red-800"
                onClick={() => changeLanguage("en")}
              >
                <span className="flag-icon flag-icon-fr"></span>
              </button>
            )}

            {i18n.language !== "fr" && (
              <button
                type="button"
                className="cursor-pointer text-xs hover:text-red-800"
                onClick={() => changeLanguage("fr")}
              >
                <span className="flag-icon flag-icon-gb"></span>
              </button>
            )}
            {pageState === "Profile" && (
              <button
                type="submit"
                className="cursor-pointer text-2xl hover:text-red-800 "
                onClick={() => navigateAndCloseMobileMenu("/savedlistings")}
              >
                <PiHeartDuotone />
              </button>
            )}
            <li
              className="py-2  w-full text-center rounded-xl bg-white text-black border-2"
              onClick={() => navigateAndCloseMobileMenu("/help")}
            >
              {t("help")}
            </li>

            {pageState === "Sign in" && (
              <>
                <li
                  className={`py-2 w-full text-center rounded-xl ${
                    pageState === "Sign in"
                      ? "bg-white text-black border-2"
                      : "bg-black text-white border-2"
                  }`}
                  onClick={() => navigateAndCloseMobileMenu("/sign-in")}
                >
                  {t(pageState)}
                </li>
                <li
                  className="py-2 w-full text-center rounded-xl mb-14 bg-custom-red text-white "
                  onClick={() => navigateAndCloseMobileMenu("/sign-up")}
                >
                  {t("join")}
                </li>
              </>
            )}

            {pageState === "Profile" && (
              <li
                className="py-2  w-full text-center rounded-xl bg-white text-black border-2"
                onClick={() => navigateAndCloseMobileMenu("/mylistings")}
              >
                {t("listings")}
              </li>
            )}
            {pageState === "Profile" && (
              <li
                className={`py-2  w-full text-center rounded-xl  ${
                  pathMatchRoute("/sign-in") || pathMatchRoute("/profile")
                    ? " bg-gray-200 text-black border-2"
                    : " bg-gray-200  text-black border-2"
                }`}
                onClick={() => navigateAndCloseMobileMenu("/profile")}
              >
                {t(pageState)}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );

  function navigateAndCloseMobileMenu(route) {
    navigate(route);
    closeMobileMenu();
  }
  function navigateFilterAndCloseMobileMenu(route) {
    navigateToResults(route);
    closeMobileMenu();
  }
}
