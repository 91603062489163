import { Link, useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { GrPrevious, GrNext } from "react-icons/gr";
import Moment from "react-moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingPlaceholder from "./ListingPlaceholder";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import { PiHeartFill, PiHeartDuotone } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import beyttyicon from "../assets/svg/beyttyicon.svg";
import { BsBadge3D } from "react-icons/bs";


export default function ListingItemHover({
  listing,
  id,
  onEdit,
  onDelete,
  setHoveredListingId,
}) {
  const [isFavorite, setIsFavorite] = useState(false);
  const auth = getAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const db = getFirestore();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language); // Update moment locale when language changes
  }, [i18n.language]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const agentRef = doc(db, "agents", auth.currentUser.uid);
        const adminRef = doc(db, "admins", auth.currentUser.uid);

        const [userDoc, agentDoc, adminDoc] = await Promise.all([
          getDoc(userRef),
          getDoc(agentRef),
          getDoc(adminRef),
        ]);

        if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
          const userData = userDoc.exists()
            ? userDoc.data()
            : agentDoc.exists()
            ? agentDoc.data()
            : adminDoc.data();

          if (
            userData.favoriteListings &&
            userData.favoriteListings.includes(id)
          ) {
            setIsFavorite(true);
          }
        } else {
          navigate("/sign-up"); // Redirect to sign-up page if user is not found in any collection
        }
      }
    };

    checkFavoriteStatus();
  }, [auth.currentUser, db, id, navigate]);

  useEffect(() => {
    const checkIfAdmin = async () => {
      const adminRef = doc(db, "admins", listing.userRef);
      const adminDoc = await getDoc(adminRef);
      if (adminDoc.exists()) {
        setIsAdmin(true);
      }
    };

    checkIfAdmin();
  }, [listing.userRef, db]);

  const toggleFavorite = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!auth.currentUser) {
      navigate("/sign-in"); // Redirect to sign-up page if user is not connected
      return;
    }

    if (auth.currentUser) {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const agentRef = doc(db, "agents", auth.currentUser.uid);
      const adminRef = doc(db, "admins", auth.currentUser.uid);

      const [userDoc, agentDoc, adminDoc] = await Promise.all([
        getDoc(userRef),
        getDoc(agentRef),
        getDoc(adminRef),
      ]);

      if (userDoc.exists() || agentDoc.exists() || adminDoc.exists()) {
        const ref = userDoc.exists()
          ? userRef
          : agentDoc.exists()
          ? agentRef
          : adminRef;
        const userData = userDoc.exists()
          ? userDoc.data()
          : agentDoc.exists()
          ? agentDoc.data()
          : adminDoc.data();

        if (
          userData.favoriteListings &&
          userData.favoriteListings.includes(id)
        ) {
          await updateDoc(ref, {
            favoriteListings: arrayRemove(id),
          });
          setIsFavorite(false);
        } else {
          await updateDoc(ref, {
            favoriteListings: arrayUnion(id),
          });
          setIsFavorite(true);
        }
      } else {
        navigate("/sign-up"); // Redirect to sign-up page if user is not found in any collection
      }
    }
  };

  const images =
    listing.imgUrls && listing.imgUrls.length > 0 ? listing.imgUrls : [null]; // Use null to conditionally render the placeholder

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  // Handler to stop propagation
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const nextArrow = <GrNext onClick={stopPropagation} />;
  const prevArrow = <GrPrevious onClick={stopPropagation} />;

  return (
    <li
      className="flex flex-col relative justify-between items-center overflow-hidden rounded-xl shadow-md bg-white m-2 md:m-1 text-black transition-all duration-300"
      onMouseEnter={() => {
        setHoveredListingId(id);
      }}
      onMouseLeave={() => {
        setHoveredListingId(null);
      }}
    >
      <Link className="contents" to={`/listingdetails/${id}`}>
        <Slider
          className="w-full"
          dots={false}
          arrows={true}
          nextArrow={<GrNext />}
          prevArrow={<GrPrevious />}
          infinite={true}
        >
          {images.map((imageUrl, index) => (
            <div className="relative" onClick={scrollToTop} key={index}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={`Image ${index}`}
                  className="h-[170px] w-full object-cover"
                  onClick={scrollToTop}
                />
              ) : (
                <ListingPlaceholder /> // Render placeholder if imageUrl is null
              )}
              {index === 0 && (
                <>
                  <Moment
                    locale={i18n.language}
                    className="absolute top-2 left-2 items-center justify-center bg-custom-red bg-opacity-50 text-white font-semibold rounded-xl px-2 py-1 shadow-lg text-xs"
                    fromNow
                  >
                    {listing.timestamp?.toDate()}
                  </Moment>
                  {isAdmin && ( // Conditionally render the exclusive listing text
                    <>
                      <div className="absolute flex bottom-2 left-2   bg-opacity-100 font-semibold gap-1 text-black shadow-lg text-xs">
                        <div className="flex rounded-lg items-center px-2 py-0.5 border-2 border-white bg-white">
                          <img
                            src={beyttyicon}
                            alt="Beytty Icon"
                            className="w-4 h-4 mr-1"
                          />
                          {t("beyttyexclusive")}
                        </div>
                        {listing.tourLink && (
                          <div className="flex gap-1 items-center rounded-lg px-2 py-0.5 border border-red-300 bg-red-50">
                            {i18n.language === "fr" && t("3dtour")}
                            <BsBadge3D className="text-lg" />
                            {i18n.language === "en" && t("3dtour")}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {!isAdmin &&
                    listing.tourLink && ( // Conditionally render the exclusive listing text
                      <div className="absolute items-center flex bottom-2 left-2 gap-1 bg-red-50 border border-red-300 bg-opacity-100 font-semibold rounded-lg px-2 py-0.5 text-black shadow-lg text-xs">
                        {i18n.language === "fr" && t("3dtour")}
                        <BsBadge3D className="text-lg" />
                        {i18n.language === "en" && t("3dtour")}
                      </div>
                    )}
                  <div className="absolute top-2 right-2">
                    {isFavorite ? (
                      <PiHeartFill
                        className="text-white hover:text-gray-100 text-3xl transition-all ease-in-out duration-100 cursor-pointer"
                        onClick={toggleFavorite}
                      />
                    ) : (
                      <PiHeartDuotone
                        className="text-white hover:opacity-100 opacity-70 bg-opacity-50 text-3xl transition-all ease-in-out duration-100 hover:text-red-500  cursor-pointer"
                        onClick={toggleFavorite}
                      />
                    )}
                  </div>{" "}
                </>
              )}
            </div>
          ))}
        </Slider>
      </Link>

      <Link
        className="contents"
        to={`/listingdetails/${id}`}
        onClick={scrollToTop}
      >
        <div className="w-full p-[10px]">
          <div className="flex items-center">
            <div
              className={`w-3 h-3 ${
                listing.type === "rent" ? "bg-yellow-500" : "bg-green-600"
              } rounded-full mr-2`}
            ></div>
            <p className="flex text-black text-sm opacity-80  text-center font-light">
              {listing.listingType === "apartment"
                ? t("apartment")
                : listing.listingType === "villa"
                ? t("villa")
                : listing.listingType === "riad"
                ? t("riad")
                : listing.listingType === "land"
                ? t("land")
                : listing.listingType === "office"
                ? t("office")
                : listing.listingType === "commercial"
                ? t("commercial")
                : listing.listingType === "farmhouse"
                ? t("farmhouse")
                : listing.listingType}{" "}
            </p>
            <p className="flex ml-1 lowercase text-black text-sm opacity-80  text-center font-light">
              {listing.type === "rent" ? t("forrent") : t("forsale")}
            </p>
          </div>

          <p className="text-[#1d1d1d] font-bold custom-font text-lg mt-1">
            {listing.offer
              ? `${listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} DH`
              : `${listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} DH`}
            {listing.type === "rent" && t("month")}
          </p>

          <div className="flex items-center font-semibold space-x-1">
            {listing.listingType != "land" &&
              listing.listingType != "office" &&
              listing.listingType != "commercial" && (
                <div className="flex items-center space-x-1">
                  <p className="text-xs">
                    {listing.bedrooms > 1
                      ? `${listing.bedrooms} ${t("Beds")}`
                      : `${t("oneBed")}`}
                  </p>
                </div>
              )}
            {listing.listingType != "land" &&
              listing.listingType != "office" &&
              listing.listingType != "commercial" && (
                <div className="flex items-center space-x-1">
                  <p className="text-xs">
                    {listing.bathrooms > 1
                      ? `| ${listing.bathrooms} ${t("Baths")} |`
                      : `${t("oneBath")}`}
                  </p>
                </div>
              )}
            <div className="flex items-center space-x-1">
              <p className="text-xs">
                {listing.size > 1 ? ` ${listing.size} m²` : "| 1 m²"}
              </p>
            </div>
          </div>
          <div className="flex items-center space-x-1">
            <p className="font-light opacity-80 text-sm mb-[2px] capitalize text-black truncate max-w-[200px] md:max-w-[300px]">
              {listing.address}
            </p>
          </div>
        </div>
      </Link>

      {onDelete && (
        <MdDelete
          className="absolute bottom-2 right-2 h-[25px] text-xl cursor-pointer text-red-600"
          onClick={() => onDelete(listing.id)}
        />
      )}
      {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-[25px] text-xl cursor-pointer text-black"
          onClick={() => onEdit(listing.id)}
        />
      )}
    </li>
  );
}
