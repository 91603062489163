import { useState, useRef, useEffect } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Typography } from "@mui/material";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import { TbElevator } from "react-icons/tb";
import { LuSofa } from "react-icons/lu";
import { LuParkingSquare } from "react-icons/lu";
import { TbAirConditioning } from "react-icons/tb";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { FaSwimmingPool } from "react-icons/fa";
import { MdBalcony } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdSatelliteAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { MdDone } from "react-icons/md";
import Bar from "../components/Bar";
import {
  BsFillSunFill,
  BsFillSunriseFill,
  BsFillSunsetFill,
  BsSun,
} from "react-icons/bs";




registerPlugin(FilePondPluginImagePreview);

export default function BeyttyListing() {
      const { t } = useTranslation();

  console.log("CreateListingPopUp Rendered");
  const [searchQuery, setSearchQuery] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectLocation = (location) => {
    if (location && location.center) {
      // Set the viewport to the selected location
      const { center } = location;
      setViewport({
        latitude: center[1],
        longitude: center[0],
        zoom: 12, // Adjust the zoom level as needed
      });
    }
  };

  const [addressError, setAddressError] = useState("");
  const [latitudeError, setLatitudeError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [listingTypeError, setListingTypeError] = useState("");
  const [yearBuiltError, setYearBuiltError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [bedroomsError, setBedroomsError] = useState("");
  const [bathroomsError, setBathroomsError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");


  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1899 },
    (_, index) => currentYear - index
  );

  useEffect(() => {
    let isMounted = true;

    if (searchQuery.trim() !== "") {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            searchQuery
          )}.json?types=place&country=MA&access_token=pk.eyJ1IjoibW9oYW1lZG1ha2RhZCIsImEiOiJjbHJ1eHJtZWowYWRjMmtteDlkOHV2cGRrIn0.PGWLIfsZVzG-z3JhjA4Qmw`
        )
        .then((response) => {
          if (isMounted) {
            setAutocompleteResults(response.data.features);
          }
        })
        .catch((error) => {
          if (isMounted) {
            console.error("Error fetching autocomplete results:", error);
          }
        });
    } else {
      setAutocompleteResults([]);
    }

    return () => {
      isMounted = false;
    };
  }, [searchQuery]);

  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [images, setImages] = useState([]);
  const auth = getAuth();
  const [finalStep, setFinalStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    yearBuilt: 2018,
    size: 0,
    listingType: "villa",
    bedrooms: 0,
    bathrooms: 0,
    parking: false,
    furnished: false,
    secured: false,
    city: "",
    floor: "1",
    elevator: false,
    airCondition: false,
    balcony: false,
    pool: false,
    address: "",
    description: "",
    latitude: 0,
    longitude: 0,
    regularPrice: 0,
    discountedPrice: 0,
    images: {},
    status: "pending",
    condition: "new",
    sunExposure: "allday",
  });

  const {
    type,
    yearBuilt,
    airCondition,
    size,
    listingType,
    address,
    bedrooms,
    bathrooms,
    parking,
    elevator,
    secured,
    balcony,
    pool,
    floor,
    furnished,
    description,
    regularPrice,
    discountedPrice,
  } = formData;

  const [mapStyle, setMapStyle] = useState(
      "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
  );

  const [isActive, setIsActive] = useState(false);

  const handleStyleChange = () => {
      const newStyle =
        mapStyle === "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g"
          ? "mapbox://styles/mapbox/satellite-v9"
          : "mapbox://styles/mohamedmakdad/clsqz4sct00xn01pf8o9vg38g";
      setMapStyle(newStyle);
      setIsActive(!isActive); // Toggle active state
  };

  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 31.7917, // Latitude for the center of Morocco
    longitude: -7.0926, // Longitude for the center of Morocco
    zoom: 6,
  });

  const handleMapClick = (event) => {
    const { lng, lat } = event.lngLat;
    setFormData((prevFormData) => ({
      ...prevFormData,
      latitude: lat,
      longitude: lng,
    }));
    console.log("Latitude:", lat, "Longitude:", lng);
  };

  useEffect(() => {
    let isMounted = true;
    const { latitude, longitude } = formData;
    if (latitude !== 0 && longitude !== 0) {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=pk.eyJ1IjoibW9oYW1lZG1ha2RhZCIsImEiOiJjbHJ1eHJtZWowYWRjMmtteDlkOHV2cGRrIn0.PGWLIfsZVzG-z3JhjA4Qmw`
        )
        .then((response) => {
          if (isMounted) {
            const address = response.data.features[0].place_name;
            setFormData((prevFormData) => ({
              ...prevFormData,
              address: address,
            }));
          }
        })
        .catch((error) => {
          if (isMounted) {
            console.error("Error fetching address:", error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [formData.latitude, formData.longitude]);

  const handleTypeChange = (event, newType) => {
    if (newType !== null) {
      onChange({ target: { id: "type", value: newType } });
    }
  };

  function onChange(e) {
    const { id, value, type, checked, files } = e.target;
    let updatedValue = value;

    // Handle boolean values
    if (value === "true") {
      updatedValue = true;
    } else if (value === "false") {
      updatedValue = false;
    }

    // Handle checkboxes
    if (type === "checkbox") {
      updatedValue = checked;
    }

    // Handle numeric values for specific fields
    if (
      id === "bedrooms" ||
      id === "bathrooms" ||
      id === "size" ||
      id === "floor" ||
      id === "regularPrice" ||
      id === "yearBuilt"
    ) {
      updatedValue = isNaN(value)
        ? 0
        : id === "size" || id === "regularPrice"
        ? parseFloat(value)
        : parseInt(value);
    }

    // Update state
    setFormData((prevState) => ({
      ...prevState,
      [id]: updatedValue,
    }));
  }

  async function convertToWebP(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob(
          (blob) =>
            resolve(
              new File([blob], file.name.replace(/\.[^/.]+$/, ".webp"), {
                type: "image/webp",
              })
            ),
          "image/webp"
        );
      };

      img.onerror = reject;
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
  }

  async function onSubmit(e) {
    e.preventDefault();
    // Add validation for each form field

    // Clear previous errors

    setLatitudeError("");
    setAddressError("");
    setTypeError("");
    setListingTypeError("");
    setYearBuiltError("");
    setSizeError("");
    setBedroomsError("");
    setBathroomsError("");
    setPriceError("");
    setDescriptionError("");

    // Check for latitude separately
    if (!formData.latitude) {
      setLatitudeError(t("latitudeError"));
      setCurrentStep(2);
      return;
    }

    // Validate each required field individually
    if (!formData.address) {
      setAddressError(t("addressError"));
      setCurrentStep(2);
      return;
    }

    if (!formData.type) {
      setTypeError(t("typeError"));
      setCurrentStep(3);
      return;
    }

    if (!formData.listingType) {
      setListingTypeError(t("listingTypeError"));
      setCurrentStep(3);
      return;
    }

    if (!formData.yearBuilt) {
      setYearBuiltError(t("yearBuiltError"));
      setCurrentStep(3);
      return;
    }

    if (!formData.size) {
      setSizeError(t("sizeError"));
      setCurrentStep(3);
      return;
    }

    if (!formData.bedrooms) {
      setBedroomsError(t("bedroomsError"));
      setCurrentStep(3);
      return;
    }

    if (!formData.bathrooms) {
      setBathroomsError(t("bathroomsError"));
      setCurrentStep(3);
      return;
    }

    if (formData.regularPrice <= 0) {
      setPriceError(t("priceError"));
      setCurrentStep(4);
      return;
    }

    if (!formData.description) {
      setDescriptionError(t("descriptionError"));
      setCurrentStep(5);
      return;
    }

    setLoading(true);

    if (images.length < 6) {
      setLoading(false);
      toast.error("minimum 6 images are required");
      return;
    }

    if (images.length > 8) {
      setLoading(false);
      toast.error("maximum 8 images are allowed");
      return;
    }

    async function storeImage(image) {
      // Convert image to WebP
      const webpImage = await convertToWebP(image);

      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${
          webpImage.name
        }-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, webpImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    }

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });

    const formDataCopy = {
      ...formData,
      imgUrls,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
      status: "pending",
    };

    delete formDataCopy.images;
    !formDataCopy.offer && delete formDataCopy.discountedPrice;
    const docRef = await addDoc(collection(db, "beyttylistings"), formDataCopy);
    setLoading(false);
    toast.success("Listing created");
    setFinalStep(true);
  }

  if (loading) {
    return <Bar loading={loading} />;
  }

  const stepTitles = [
    "Location",
    "Listing",
    "Pricing",
    "Description",
    "Images",
  ];

  const renderStepIndicator = () => {
    return (
      <div className="flex items-center justify-between mb-8 mt-4">
        {stepTitles.map((title, index) => (
          <div
            key={index}
            className={`flex-1 h-2 ${
              index + 1 < currentStep ||
              (finalStep && index + 1 === stepTitles.length)
                ? "bg-custom-red" // Highlight the steps before the current step and the final step
                : "bg-gray-300"
            }`}
          ></div>
        ))}
      </div>
    );
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderConfirmationStep = () => {
    return (
      <div>
        <h1 className="flex gap-2 text-2xl md:text-3xl  mb-12">
          <IoCheckmarkDoneSharp style={{ color: "#e80e02" }} />
          {t("listingsubmited")}{" "}
        </h1>
        <p className="text-gray-500 mb-2">{t("beyttysubmit")}</p>
        {/* Add any additional content or styling for the confirmation step */}
      </div>
    );
  };

  const renderStepContent = () => {
    if (finalStep) {
      return renderConfirmationStep();
    }
    switch (currentStep) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
      case 5:
        return renderStep5();
      case 6:
        return renderStep6();
      default:
        return null;
    }
  };

   const renderStep1 = () => {
     
     return (
       <div>
         <div
           onClick={nextStep}
           className="rounded-lg cursor-pointer p-8  bg-gray-100 border-2 border-gray-200 "
         >
           <p className="text-2xl mb-2">{t("whattoexpect")}</p>
           <ul className="text-sm md:text-base w-full mb-8">
             <li className="flex items-center gap-2">
               <MdDone /> {t("fullservice")}
             </li>
             <li className="flex items-center gap-2">
               <MdDone /> {t("professionalnetworks")}
             </li>
             <li className="flex items-center gap-2">
               <MdDone /> {t("administrativesupport")}
             </li>
             <li className="flex items-center gap-2">
               <MdDone /> {t("timesavings")}
             </li>
             <li className="flex items-center gap-2">
               <MdDone /> {t("marketingresource")}
             </li>
             <li className="flex items-center gap-2">
               <MdDone /> {t("3dTour")}
             </li>
           </ul>
           <p className="text-lg mb-">{t("allofthisfor")}</p>
           <div className="flex gap-4 items-center">
             <div className="flex flex-col justify-end w-full mt-2">
               <p className="text-lg md:text-xl font-semibold justify-end flex ">2.5% </p>
               <p className="text-[0.7rem] w-2/3 ml-auto justify-end text-right -mt-1 mb-4 ">
                 {t("incaseofsale")}
               </p>
               <p className="text-lg md:text-xl font-semibold text-right justify-end flex ">
                 {t("incaseofrenttext")}{" "}
               </p>
               <p className="text-[0.7rem] w-2/3 ml-auto justify-end text-right -mt-1 mb-4 ">
                 {t("incaseofrent")}
               </p>

               <button
                 className="mb-6 px-3 py-2 mt-6 w-1/2 ml-auto bg-custom-red text-white rounded-md shadow-md  active:shadow-lg transition duration-150 ease-in-out"
                 onClick={nextStep}
               >
                 {t("confirm")} ➜
               </button>
             </div>
           </div>
         </div>
       </div>
     );
   };

   const renderStep2 = () => {
     return (
       <div>
         <h1 className="flex gap-2 text-2xl md:text-3xl  mb-4">
           
           {t("location")}
         </h1>
         <p className="flex  gap-1 text-sm text-gray-500  mb-4">
           {t("clickmap")}
         </p>
         <div className="h-[400px] bg-gray-200">
           <ReactMapGL
             {...viewport}
             mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
             mapStyle={mapStyle}
             onMove={(evt) => setViewport(evt.viewport)}
             onClick={handleMapClick}
           >
             {formData.latitude !== 0 && formData.longitude !== 0 && (
               <Marker
                 latitude={formData.latitude}
                 longitude={formData.longitude}
                 offsetTop={-20}
                 offsetLeft={-10}
                 anchor="bottom" // Set the anchor point to the bottom
               >
                 <img
                   src="/MyPin.svg"
                   alt="Beytty Marker"
                   style={{ width: "40px", height: "40px" }}
                 />
               </Marker>
             )}
             <div style={{ position: "absolute", right: 10, top: 10 }}>
               <NavigationControl />
             </div>
             <div
               className="rounded w-full"
               style={{ position: "absolute", top: 10 }}
             >
               <Autocomplete
                 className="w-2/3 rounded bg-white mx-auto flex items-center justify-center"
                 options={autocompleteResults}
                 getOptionLabel={(option) => option.place_name}
                 renderInput={(params) => (
                   <TextField
                     {...params}
                     sx={{
                       "& .MuiOutlinedInput-root": {
                         "& fieldset": {
                           borderColor: "gray",
                         },
                         "&:hover fieldset": {
                           borderColor: "black",
                         },
                         "&.Mui-focused fieldset": {
                           borderColor: "black",
                         },
                         "&.Mui-focused": {
                           boxShadow: "none",
                         },
                         "& input": {
                           boxShadow: "none",
                         },
                         "& textarea": {
                           boxShadow: "none",
                         },
                       },
                     }}
                     InputLabelProps={{
                       // Add this to style the label
                       style: { lineHeight: "19px" }, // Adjust the lineHeight to vertically center the label
                     }}
                     inputProps={{
                       ...params.inputProps,
                       autoComplete: "new-password",
                       style: {
                         height: "14px",
                       },
                     }}
                     label={t("searchplaceholder")}
                     variant="outlined"
                     onChange={handleSearchChange}
                   />
                 )}
                 onChange={(event, value) => {
                   handleSelectLocation(value);
                 }}
               />
             </div>
             <div style={{ position: "absolute", left: 10, top: 10 }}>
               <div className="flex border-[1px] shadow-sm shadow-gray-500 border-black border-opacity-20 rounded flex-col">
                 <button
                   type="button"
                   className={`p-2 transition hover:bg-gray-400 ease-in-out duration-150 text-lg  rounded ${
                     isActive ? "bg-gray-400 border-white" : "bg-white"
                   }`}
                   onClick={handleStyleChange}
                 >
                   {" "}
                   <MdSatelliteAlt />
                 </button>
               </div>
             </div>
           </ReactMapGL>{" "}
         </div>
         {latitudeError && (
           <p className="text-red-500 text-sm mb-2">{latitudeError}</p>
         )}
         <input
           type="address"
           id="address"
           value={address}
           onChange={onChange}
           placeholder={t("youraddress")}
           className="w-full  mt-4 px-4 py-2 text-md color-grey-700 bg-white border-2 border-gray-300 rounded transition ease-in-out"
         />
         {addressError && (
           <p className="text-red-500 text-sm mb-6">{addressError}</p>
         )}
         <button
           className="mb-12 flex text-center justify-center px-3 py-2 mt-6 w-1/2 ml-auto bg-custom-red text-white rounded-md shadow-md  active:shadow-lg transition duration-150 ease-in-out"
           onClick={nextStep}
         >
           {t("confirm")} ➜
         </button>
       </div>
     );
   };

   const renderStep3 = () => {
     const isLand = listingType === "land";
     const isApt = listingType === "apartment";
     const isBig =
       listingType === "villa" ||
       listingType === "riad" ||
       listingType === "farmhouse";
     const isOffice = listingType === "office" || listingType === "commercial";
     const isPlace =
       listingType === "apartment" ||
       listingType === "villa" ||
       listingType === "riad" ||
       listingType === "farmhouse";
     return (
       <div>
         <h1 className="flex gap-2 text-2xl md:text-3xl  mb-12">
           <IoIosArrowBack
             className="my-1 cursor-pointer hover:opacity-70"
             onClick={prevStep}
           />
           {t("propertyinfo")}
         </h1>
         <Grid container>
           <Grid item xs={12}>
             <p className="text-gray-500 mb-2"> {t("2.1")}</p>
           </Grid>
           <Grid item xs={12}>
             <ToggleButtonGroup
               value={type}
               exclusive
               onChange={handleTypeChange}
               aria-label="sell or rent"
               fullWidth
             >
               <ToggleButton
                 sx={{
                   color: "black",
                   fontSize: "0.7rem",
                   borderColor: "black",
                   "&.Mui-selected": {
                     backgroundColor: "green",
                     color: "white",
                   },
                   "&:hover": {
                     backgroundColor: "green",
                     color: "white",
                   },
                 }}
                 value="sale"
                 aria-label="sell"
               >
                 {t("sell")}
               </ToggleButton>
               <ToggleButton
                 sx={{
                   color: "black",
                   fontSize: "0.7rem",
                   borderColor: "black",
                   "&.Mui-selected": {
                     backgroundColor: "orange",
                     color: "white",
                   },
                   "&:hover": {
                     backgroundColor: "orange",
                     color: "white",
                   },
                 }}
                 value="rent"
                 aria-label="rent"
               >
                 {t("rent")}
               </ToggleButton>
             </ToggleButtonGroup>
           </Grid>
         </Grid>
         {typeError && <p className="text-red-500 text-sm mb-2">{typeError}</p>}
         <p className="mt-8 text-gray-500 mb-2"> {t("2.2")}</p>
         <TextField
           select
           id="listingType"
           value={listingType}
           onChange={onChange}
           fullWidth
           variant="outlined"
           size="small"
           InputProps={{
             className:
               "text-xl text-gray-700 bg-white border border-gray-300 rounded",
             classes: {
               focused: "focus:text-gray-700 focus:bg-white focus:border-black",
             },
           }}
           SelectProps={{
             native: true,
           }}
         >
           <option value="villa">Villa</option>
           <option value="apartment"> {t("apartment")}</option>
           <option value="riad">Riad</option>
           <option value="farmhouse"> {t("farmhouse")}</option>
           <option value="commercial"> {t("commercial")}</option>
           <option value="office"> {t("office")}</option>
           <option value="land"> {t("land")}</option>
         </TextField>
         {listingTypeError && (
           <p className="text-red-500 text-sm mb-2">{listingTypeError}</p>
         )}
         {isApt && (
           <>
             <div>
               <p className="mt-8 text-gray-500 mb-2">{t("floor")}</p>
               <TextField
                 type="number"
                 id="floor"
                 value={floor}
                 onChange={onChange}
                 InputProps={{
                   inputProps: { min: 0, max: 50 },
                   className:
                     "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                   classes: {
                     focused:
                       "focus:text-gray-700 focus:bg-white focus:border-black",
                   },
                 }}
                 fullWidth
                 variant="outlined"
                 size="medium"
                 className="w-full"
               />
             </div>
           </>
         )}
         {isLand && (
           <>
             <div className=" grid grid-cols-2 md:grid-cols-1 gap-2 justify-between mt-8 mb-2">
               <div>
                 <p className=" text-gray-500 mb-2 "> {t("size")}</p>
                 <div className="relative w-full">
                   <TextField
                     type="number"
                     id="size"
                     value={size}
                     onChange={onChange}
                     InputProps={{
                       inputProps: { min: 1, max: 10000 },
                       className:
                         "text-xl text-gray-700 bg-white border border-gray-300 w-full rounded",
                       classes: {
                         focused:
                           "focus:text-gray-700 focus:bg-white focus:border-black",
                       },
                     }}
                     fullWidth
                     variant="outlined"
                     size="medium"
                   />
                   <div className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 text-md whitespace-nowrap">
                     m²
                   </div>
                 </div>
               </div>
             </div>
             {sizeError && (
               <p className="text-red-500 text-sm mb-6">{sizeError}</p>
             )}
           </>
         )}
         {isOffice && (
           <div className="">
             <div className=" grid grid-cols-3 md:grid-cols-3 gap-2 justify-between mt-8 mb-2">
               <div>
                 <p className=" text-gray-500 mb-2 "> {t("size")}</p>
                 <div className="relative w-full">
                   <TextField
                     type="number"
                     id="size"
                     value={size}
                     onChange={onChange}
                     InputProps={{
                       inputProps: { min: 1, max: 10000 },
                       className:
                         "text-xl text-gray-700 bg-white border border-gray-300 w-full rounded",
                       classes: {
                         focused:
                           "focus:text-gray-700 focus:bg-white focus:border-black",
                       },
                     }}
                     fullWidth
                     variant="outlined"
                     size="medium"
                   />
                   <div className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 text-md whitespace-nowrap">
                     m²
                   </div>
                 </div>
               </div>
               <div>
                 <p className=" text-gray-500 mb-2"> {t("yearbuilt")}</p>
                 <TextField
                   select
                   id="yearBuilt"
                   value={yearBuilt}
                   onChange={onChange}
                   fullWidth
                   variant="outlined"
                   size="medium"
                   InputProps={{
                     className:
                       "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                     classes: {
                       focused:
                         "focus:text-gray-700 focus:bg-white focus:border-black",
                     },
                   }}
                   SelectProps={{
                     native: true,
                   }}
                 >
                   {years.map((year, index) => (
                     <option key={index} value={year}>
                       {year}
                     </option>
                   ))}
                 </TextField>
               </div>
               <div>
                 <p className=" text-gray-500 mb-2"> {t("floor")}</p>
                 <TextField
                   type="number"
                   id="floor"
                   value={floor}
                   onChange={onChange}
                   InputProps={{
                     inputProps: { min: 0, max: 50 },
                     className:
                       "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                     classes: {
                       focused:
                         "focus:text-gray-700 focus:bg-white focus:border-black",
                     },
                   }}
                   fullWidth
                   variant="outlined"
                   size="medium"
                   className="w-full"
                 />
               </div>
             </div>
             {sizeError && (
               <p className="text-red-500 text-sm mb-6">{sizeError}</p>
             )}
             <div>
               <p className="mt-8 text-gray-500 mb-2">{t("sunexposure")}</p>
               <ToggleButtonGroup
                 value={formData.sunExposure}
                 exclusive
                 fullWidth
                 onChange={(e, value) => {
                   if (value)
                     onChange({ target: { id: "sunExposure", value } });
                 }}
               >
                 <ToggleButton
                   value="allday"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffd500",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffd500",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunFill className="text-lg" />
                   {t("allday")}
                 </ToggleButton>
                 <ToggleButton
                   value="morning"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffdc2b",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffdc2b",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunriseFill className="text-lg" /> {t("morning")}
                 </ToggleButton>
                 <ToggleButton
                   value="afternoon"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffe252",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffe252",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunsetFill className="text-lg" /> {t("afternoon")}
                 </ToggleButton>

                 <ToggleButton
                   value="none"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#fff1a8",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#fff1a8",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsSun className="text-lg" /> {t("nosun")}
                 </ToggleButton>
               </ToggleButtonGroup>
             </div>
             <div>
               <p className="mt-8 text-gray-500 mb-2">{t("condition")}</p>
               <ToggleButtonGroup
                 color="primary"
                 value={formData.condition}
                 exclusive
                 fullWidth
                 onChange={(e, value) => {
                   if (value) onChange({ target: { id: "condition", value } });
                 }}
               >
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="new"
                 >
                   {t("brandnew")}
                 </ToggleButton>
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="good"
                 >
                   {t("goodcondition")}
                 </ToggleButton>
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="renovation"
                 >
                   {t("needsrenovation")}
                 </ToggleButton>
               </ToggleButtonGroup>
             </div>

             <div className="">
               <p className="mt-8 text-gray-500 mb-3 ">Extras</p>
               <div className=" grid grid-cols-2 md:grid-cols-3 gap-4">
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={parking}
                       onChange={onChange}
                       id="parking"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <LuParkingSquare style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "4px" }}>Parking</span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={furnished}
                       onChange={onChange}
                       id="furnished"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <LuSofa style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "4px" }}>
                         {" "}
                         {t("furnished")}
                       </span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={elevator}
                       onChange={onChange}
                       id="elevator"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <TbElevator style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "4px" }}>
                         {" "}
                         {t("elevator")}
                       </span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={airCondition}
                       onChange={onChange}
                       id="airCondition"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <TbAirConditioning style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "8px" }}>A/C</span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={secured}
                       onChange={onChange}
                       id="secured"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <GiPoliceOfficerHead style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "4px" }}>
                         {" "}
                         {t("secured")}
                       </span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
               </div>
             </div>
           </div>
         )}
         {isPlace && (
           <div>
             <div className=" grid grid-cols-2 md:grid-cols-2 gap-2 justify-between mt-8 mb-2">
               <div>
                 <p className="text-gray-500 mb-2 "> {t("beds")}</p>
                 <TextField
                   type="number"
                   id="bedrooms"
                   value={bedrooms}
                   onChange={onChange}
                   InputProps={{
                     inputProps: { min: 1, max: 50 },
                     className:
                       "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                     classes: {
                       focused:
                         "focus:text-gray-700 focus:bg-white focus:border-black",
                     },
                   }}
                   fullWidth
                   variant="outlined"
                   size="medium"
                   className="w-full"
                 />
               </div>
               <div>
                 <p className="text-gray-500 mb-2  "> {t("baths")}</p>
                 <TextField
                   type="number"
                   id="bathrooms"
                   value={bathrooms}
                   onChange={onChange}
                   InputProps={{
                     inputProps: { min: 1, max: 50 },
                     className:
                       "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                     classes: {
                       focused:
                         "focus:text-gray-700 focus:bg-white focus:border-black",
                     },
                   }}
                   fullWidth
                   variant="outlined"
                   size="medium"
                 />
               </div>
               <div>
                 <p className=" text-gray-500 mb-2 "> {t("size")}</p>
                 <div className="relative w-full">
                   <TextField
                     type="number"
                     id="size"
                     value={size}
                     onChange={onChange}
                     InputProps={{
                       inputProps: { min: 1, max: 10000 },
                       className:
                         "text-xl text-gray-700 bg-white border border-gray-300 w-full rounded",
                       classes: {
                         focused:
                           "focus:text-gray-700 focus:bg-white focus:border-black",
                       },
                     }}
                     fullWidth
                     variant="outlined"
                     size="medium"
                   />
                   <div className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 text-md whitespace-nowrap">
                     m²
                   </div>
                 </div>
               </div>
               <div>
                 <p className=" text-gray-500 mb-2"> {t("yearbuilt")}</p>
                 <TextField
                   select
                   id="yearBuilt"
                   value={yearBuilt}
                   onChange={onChange}
                   fullWidth
                   variant="outlined"
                   size="medium"
                   InputProps={{
                     className:
                       "text-xl text-gray-700 bg-white border border-gray-300 rounded",
                     classes: {
                       focused:
                         "focus:text-gray-700 focus:bg-white focus:border-black",
                     },
                   }}
                   SelectProps={{
                     native: true,
                   }}
                 >
                   {years.map((year, index) => (
                     <option key={index} value={year}>
                       {year}
                     </option>
                   ))}
                 </TextField>
               </div>
             </div>
             {bedroomsError && (
               <p className="text-red-500 text-sm mb-6">{bedroomsError}</p>
             )}
             {bathroomsError && (
               <p className="text-red-500 text-sm mb-6">{bathroomsError}</p>
             )}
             {sizeError && (
               <p className="text-red-500 text-sm mb-6">{sizeError}</p>
             )}
             {yearBuiltError && (
               <p className="text-red-500 text-sm mb-6">{yearBuiltError}</p>
             )}
             <div>
               <p className="mt-8 text-gray-500 mb-2">{t("sunexposure")}</p>
               <ToggleButtonGroup
                 value={formData.sunExposure}
                 exclusive
                 fullWidth
                 onChange={(e, value) => {
                   if (value)
                     onChange({ target: { id: "sunExposure", value } });
                 }}
               >
                 <ToggleButton
                   value="allday"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffd500",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffd500",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunFill className="text-lg" />
                   {t("allday")}
                 </ToggleButton>
                 <ToggleButton
                   value="morning"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffdc2b",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffdc2b",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunriseFill className="text-lg" /> {t("morning")}
                 </ToggleButton>
                 <ToggleButton
                   value="afternoon"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#ffe252",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#ffe252",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsFillSunsetFill className="text-lg" /> {t("afternoon")}
                 </ToggleButton>

                 <ToggleButton
                   value="none"
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#fff1a8",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#fff1a8",
                       color: "black",
                     },
                   }}
                   className="items-center flex flex-col gap-1"
                 >
                   <BsSun className="text-lg" /> {t("nosun")}
                 </ToggleButton>
               </ToggleButtonGroup>
             </div>
             <div>
               <p className="mt-8 text-gray-500 mb-2">{t("condition")}</p>
               <ToggleButtonGroup
                 color="primary"
                 value={formData.condition}
                 exclusive
                 fullWidth
                 onChange={(e, value) => {
                   if (value) onChange({ target: { id: "condition", value } });
                 }}
               >
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="new"
                 >
                   {t("brandnew")}
                 </ToggleButton>
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="good"
                 >
                   {t("goodcondition")}
                 </ToggleButton>
                 <ToggleButton
                   sx={{
                     color: "black",
                     fontSize: "0.7rem",
                     borderColor: "black",
                     "&.Mui-selected": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                     "&:hover": {
                       backgroundColor: "#d4d4d4",
                       color: "black",
                     },
                   }}
                   value="renovation"
                 >
                   {t("needsrenovation")}
                 </ToggleButton>
               </ToggleButtonGroup>
             </div>
             <p className="mt-8 text-gray-500 mb-3 ">Extras</p>
             <div className=" grid grid-cols-2 md:grid-cols-3 gap-4">
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={parking}
                     onChange={onChange}
                     id="parking"
                     className="mr-2"
                     sx={{
                       color: "red",
                       "&.Mui-checked": {
                         color: "red",
                       },
                     }}
                   />
                 }
                 label={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <LuParkingSquare style={{ fontSize: 24 }} />{" "}
                     {/* Adjust the fontSize here */}
                     <span style={{ marginLeft: "4px" }}>Parking</span>{" "}
                     {/* Adjust spacing if needed */}
                   </div>
                 }
               />
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={furnished}
                     onChange={onChange}
                     id="furnished"
                     className="mr-2"
                     sx={{
                       color: "red",
                       "&.Mui-checked": {
                         color: "red",
                       },
                     }}
                   />
                 }
                 label={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <LuSofa style={{ fontSize: 24 }} />{" "}
                     {/* Adjust the fontSize here */}
                     <span style={{ marginLeft: "4px" }}>
                       {" "}
                       {t("furnished")}
                     </span>{" "}
                     {/* Adjust spacing if needed */}
                   </div>
                 }
               />
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={elevator}
                     onChange={onChange}
                     id="elevator"
                     className="mr-2"
                     sx={{
                       color: "red",
                       "&.Mui-checked": {
                         color: "red",
                       },
                     }}
                   />
                 }
                 label={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <TbElevator style={{ fontSize: 24 }} />{" "}
                     {/* Adjust the fontSize here */}
                     <span style={{ marginLeft: "4px" }}>
                       {" "}
                       {t("elevator")}
                     </span>{" "}
                     {/* Adjust spacing if needed */}
                   </div>
                 }
               />
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={airCondition}
                     onChange={onChange}
                     id="airCondition"
                     className="mr-2"
                     sx={{
                       color: "red",
                       "&.Mui-checked": {
                         color: "red",
                       },
                     }}
                   />
                 }
                 label={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <TbAirConditioning style={{ fontSize: 24 }} />{" "}
                     {/* Adjust the fontSize here */}
                     <span style={{ marginLeft: "8px" }}>A/C</span>{" "}
                     {/* Adjust spacing if needed */}
                   </div>
                 }
               />
               <FormControlLabel
                 control={
                   <Checkbox
                     checked={secured}
                     onChange={onChange}
                     id="secured"
                     className="mr-2"
                     sx={{
                       color: "red",
                       "&.Mui-checked": {
                         color: "red",
                       },
                     }}
                   />
                 }
                 label={
                   <div style={{ display: "flex", alignItems: "center" }}>
                     <GiPoliceOfficerHead style={{ fontSize: 24 }} />{" "}
                     {/* Adjust the fontSize here */}
                     <span style={{ marginLeft: "4px" }}>
                       {" "}
                       {t("secured")}
                     </span>{" "}
                     {/* Adjust spacing if needed */}
                   </div>
                 }
               />
               {isBig && (
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={pool}
                       onChange={onChange}
                       id="pool"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <FaSwimmingPool style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "4px" }}>
                         {" "}
                         {t("pool")}
                       </span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
               )}
               {isApt && (
                 <FormControlLabel
                   control={
                     <Checkbox
                       checked={balcony}
                       onChange={onChange}
                       id="balcony"
                       className="mr-2"
                       sx={{
                         color: "red",
                         "&.Mui-checked": {
                           color: "red",
                         },
                       }}
                     />
                   }
                   label={
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <MdBalcony style={{ fontSize: 24 }} />{" "}
                       {/* Adjust the fontSize here */}
                       <span style={{ marginLeft: "8px" }}>
                         {" "}
                         {t("balcony")}
                       </span>{" "}
                       {/* Adjust spacing if needed */}
                     </div>
                   }
                 />
               )}
             </div>
           </div>
         )}
         <div className="flex space-x-6 mt-12 justify-start mb-6">
           <button
             className="mb-12 flex text-center justify-center px-3 py-2 mt-6 w-1/2 ml-auto bg-custom-red text-white rounded-md shadow-md  active:shadow-lg transition duration-150 ease-in-out"
             onClick={nextStep}
           >
             {t("confirm")} ➜
           </button>
         </div>
       </div>
     );
   };

   const renderStep4 = () => {
     return (
       <div className=" w-full">
         <h1 className="flex gap-2 w-full text-2xl md:text-3xl  mb-12">
           {" "}
           <IoIosArrowBack
             className="mt-1 cursor-pointer hover:opacity-70"
             onClick={prevStep}
           />{" "}
           {t("pricing")}
         </h1>
         <div>
           <p className=" text-gray-500 mb-2 "> {t("price")}</p>
           <div className="relative w-full">
             <TextField
               type="number"
               id="regularPrice"
               value={regularPrice}
               onChange={onChange}
               InputProps={{
                 inputProps: { min: 100, max: 100000000 },
                 className:
                   "text-xl text-gray-700 bg-white border border-gray-300 w-full rounded",
                 classes: {
                   focused:
                     "focus:text-gray-700 focus:bg-white focus:border-black",
                 },
               }}
               fullWidth
               variant="outlined"
               size="medium"
             />
             {type === "rent" && (
               <div className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 text-md whitespace-nowrap">
                 DH {t("month")}
               </div>
             )}
             {type !== "rent" && (
               <div className="absolute right-4 sm:right-10 top-1/2 transform -translate-y-1/2 text-md whitespace-nowrap">
                 DH
               </div>
             )}
           </div>
         </div>
         {priceError && (
           <p className="text-red-500 text-sm mb-2">{priceError}</p>
         )}

         <div className="flex space-x-6 mt-12 justify-start mb-6">
           <button
             className="mb-12 flex text-center justify-center px-3 py-2 mt-6 w-1/2 ml-auto bg-custom-red text-white rounded-md shadow-md  active:shadow-lg transition duration-150 ease-in-out"
             onClick={nextStep}
           >
             {t("confirm")} ➜
           </button>
         </div>
       </div>
     );
   };

   const renderStep5 = () => {
     return (
       <div>
         <h1 className="flex gap-2 text-2xl md:text-3xl mb-12">
           {" "}
           <IoIosArrowBack
             className="mt-1 cursor-pointer hover:opacity-70"
             onClick={prevStep}
           />{" "}
           Description.
         </h1>

         <TextField
           id="description"
           value={description}
           onChange={onChange}
           placeholder="..."
           required
           fullWidth
           multiline
           rows={4} // Adjust the number of rows as needed
           variant="outlined"
           margin="normal"
           inputProps={{ maxLength: 1000 }}
           sx={{
             "& .MuiOutlinedInput-root": {
               "& fieldset": {
                 borderColor: "gray",
               },
               "&:hover fieldset": {
                 borderColor: "black",
               },
               "&.Mui-focused fieldset": {
                 borderColor: "black",
               },
               "&.Mui-focused": {
                 boxShadow: "none",
               },
               "& input": {
                 boxShadow: "none",
               },
               "& textarea": {
                 boxShadow: "none",
               },
             },
           }}
         />
         <div className="flex mx-auto justify-end">
           <Typography variant="caption" color="textSecondary">
             {description.length}/1000
           </Typography>
         </div>
         {descriptionError && (
           <p className="text-red-500 text-sm mb-2">{descriptionError}</p>
         )}
         <div className="flex space-x-6 mt-12 justify-start mb-6">
           <button
             className="mb-12 flex text-center justify-center px-3 py-2 mt-6 w-1/2 ml-auto bg-custom-red text-white rounded-md shadow-md  active:shadow-lg transition duration-150 ease-in-out"
             onClick={nextStep}
           >
             {t("confirm")} ➜
           </button>
         </div>
       </div>
     );
   };

   const renderStep6 = () => {
     const handleFileChange = async (files) => {
       const validFiles = [];

       for (const file of files) {
         const image = new Image();
         image.src = URL.createObjectURL(file.file);

         await new Promise((resolve) => {
           image.onload = () => {
             const aspectRatio = image.width / image.height;
             const tolerance = 9999; // Adjust the tolerance level as needed

             if (Math.abs(aspectRatio - 16 / 9) <= tolerance) {
               validFiles.push(file.file);
             }

             resolve();
           };
         });
       }

       if (validFiles.length < files.length) {
         toast.error("Images should have an aspect ratio close to 16:9.");
       }

       setImages(validFiles);
     };

     // Custom options for FilePond
     const filePondOptions = {
       allowMultiple: true,
       acceptedFileTypes: ["image/*"],
       maxFiles: 8,
       imagePreviewHeight: 100, // Set your desired height for each image preview
       imageCropAspectRatio: "16:9", // Crop images to a 16:9 aspect ratio
     };

     return (
       <div>
         <h1 className=" flex gap-2 text-2xl md:text-3xl  mb-4">
           {" "}
           <IoIosArrowBack
             className="mt-1 cursor-pointer hover:opacity-70"
             onClick={prevStep}
           />{" "}
           Images.
         </h1>
         <div className="mb-6">
           <FilePond
             files={images}
             name="filepond"
             labelIdle={`<span style="display: block; text-align: center;">
             Drag and drop your files here or <span style="color: #c51700; text-decoration: underline;">Browse</span>
           </span>
           <span style="display: block; text-align: center; margin-top: 5px; font-size: 12px; color: #666;">
             Image on top will be the cover | min 6 - max 8 
           </span>`}
             onupdatefiles={handleFileChange}
             {...filePondOptions}
           />
         </div>
         <button
           type="submit"
           className="mb-6 w-full px-7 py-3 bg-custom-red text-white font-medium text-sm uppercase rounded shadow-md hover:opacity-70 hover:shadow-lg focus:bg-black focus:shadow-lg active:bg-black active:shadow-lg transition duration-150 ease-in-out"
         >
           {t("createlisting")}
         </button>
       </div>
     );
   };

   return (
     <main>
       <div className="mt-8 flex md:h-[800px] overflow-hidden justify-center gap-8 px-4 max-w-6xl mx-auto">
         <div className="hidden overflow-y-hidden md:block bg-gray-100 text-black shadow-lg p-12 rounded-xl border-2 w-1/2 h-[750px]">
           <p className="text-4xl custom-font mb-8 font-semibold ">
             {t("sellquicklywithbeytty")}
           </p>
           <div className="text-xl font-semibold ">
             <p className={currentStep === 2 ? "text-red-500" : ""}>{t("step")} 1 </p>
             <p className="mb-2 font-light"> {t("location")}.</p>
             <p className={currentStep === 3 ? "text-red-500" : ""}>{t("step")} 2 </p>
             <p className="mb-2 font-light">Info.</p>
             <p className={currentStep === 4 ? "text-red-500" : ""}>{t("step")} 3 </p>
             <p className="mb-2 font-light"> {t("pricing")}.</p>
             <p className={currentStep === 5 ? "text-red-500" : ""}>{t("step")} 4 </p>
             <p className="mb-2 font-light"> Description.</p>
             <p className={currentStep === 6 && !finalStep ? "text-red-500" : ""}>{t("step")} 5 </p>
             <p className="mb-2 font-light"> Images. </p>
             <p className={finalStep ? "text-red-500" : ""}>{t("step")} 6 </p>
             <p className="mb-2 font-light">{t("waitforourcontact")}</p>
             <p>{t("step")} 7 </p>
             <p className="mb-2 font-light"> {t("listed")}</p>
           </div>
           <a className="flex ">
             <img
               src={process.env.PUBLIC_URL + "/LogoW.png"}
               alt="Beytty Black Logo"
               className="flex h-10 mt-5"
             />
           </a>
         </div>
         <form
           onSubmit={onSubmit}
           className="w-full overflow-y-auto px-4 md:w-1/2 mb-12 "
           onClick={(e) => e.stopPropagation()}
         >
           {renderStepIndicator()}
           {renderStepContent()}
         </form>
       </div>
     </main>
   );
}
